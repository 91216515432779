import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import indoordata from './indoorArrayData.js'
import outdoordata from './outDoorArrayData.js'
import { useParams } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
function Sidebar({ updateHighte, hight, idScreen, updateIdScreen, setDataIdScreenSelect, setDataShare, setImageBgSelect, setWHData, WHData,
  selectEnv,
  setSelectEnv,
  setSelectEnvDataH,
  widthCheck,
  setColRom,
  colRom,
  setWidthCheck,
  setHeightCheck,
  heightCheck,
  dataIdScreenSelect,
  setHeightCheckMain,
  heightCheckMain

}) {
  const params = useParams();
  const [message, setMessage] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openOutDoor, setOpenOutDoor] = React.useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const [openModelAdd, setOpenModelAdd] = React.useState(false);
  const [openModelView, setOpenModelView] = React.useState(false);
  const [openModelStandar, setOpenModelStandar] = React.useState(false);
  const [dataIndex, setDataIndex] = React.useState(0);

  const [datacustom, setDatacustom] = React.useState({
    pixelPitch: 0,
    cabinetSizeWxhW: 0,
    cabinetSizeWxhH: 0,
    cabinetResolutionPXW: 0,
    cabinetResolutionPXH: 0,


  })

  const [column, setcolumn] = React.useState(0);
  const [selectModelText, setSelectModelText] = React.useState(0);

  const [selectEnvData, setSelectEnvData] = React.useState(indoordata);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenAdd = () => {
    setOpenModelAdd(true);
  };
  const handleClickOpenOutDoor = () => {
    setOpenOutDoor(true);
  };
  const handleCloseOutDoor = () => {
    setOpenOutDoor(false);
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleClickOpenModel = () => {
    setOpenModel(true);
  };
  const handleCloseModel = () => {
    setOpenModel(false);
  };

  const handleCloseModelAdd = () => {
    setOpenModelAdd(false)
  }
  const handleClickOpenModelView = () => {
    setOpenModelView(true);
  };
  const handleClickOpenStandar = () => {
    setOpenModelStandar(true);
  };
  const handleCloseModelStandar = () => {
    setOpenModelStandar(false);
  };
  const handleCloseModelView = () => {
    setOpenModelView(false);
  };
  const increasehighte = () => {
    let xvalue = (WHData.height * 100) / (selectEnvData[dataIndex].cabinetSizeWxhH / 10);
    let valueCol = (WHData.groundHeight * 100) + colRom.row
    // alert(xvalue)
    // alert(colRom.row + WHData.groundHeight)
    // if (heightCheck) {

    //   setWHData({ ...WHData, "groundHeight": WHData.groundHeight + 0.25 })

    // } else {
    //   setMessage("Screen out of boundary.")
    //   setOpenAlert(true)
    // }
    if (colRom.row + WHData.groundHeight < Math.floor(xvalue)) {
      if (WHData.groundHeight < 1) {
        setWHData({ ...WHData, "groundHeight": WHData.groundHeight + 0.25 })
      } else {
        setMessage("Screen out of boundary.")
        setOpenAlert(true)
      }

    } else {
      setMessage("Screen out of boundary.")
      setOpenAlert(true)
    }


  }

  const decreaseHight = () => {
    if (WHData.groundHeight > 0) {
      setHeightCheck(true)
      setWHData({ ...WHData, "groundHeight": WHData.groundHeight - 0.25 })
    }


  }

  React.useEffect(() => {
    setDataShare(colRom)
    setSelectEnvDataH(selectEnvData)
    console.log(params)
  })
  const fileInputRef = useRef(null);


  const imageUpload = (e) => {
    const reader = new FileReader();
    console.log(e.target.files);
    const selectedFiles = e.target.files;
    setImageBgSelect(URL.createObjectURL(e.target.files?.[0]));


  }

  const handleCloseAlert = () => {

    setOpenAlert(false);
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  }

  const [activity, setActivity] = React.useState('');

  const handleChangeAdd = (event) => {
    setActivity(event.target.value);
  };
  return (
    <>
      <div className="" style={{ fontSize: '14px' }}>
        <div className="row mt-3">
          <div className="col-md-12 ">
            <h6 className="text-center text-white">ROOM CONFIGURATOR</h6>
          </div>
          <div className="col-md-12 card bg-dark text-white">
            <br />
            <div class="form-group">
              <label for="ENVIRONMENT">ENVIRONMENT</label>
              <Button variant="contained" onClick={handleClickOpen} size="small" style={{ width: "100%" }}>{selectEnv == 0 ? "INDOOR" : "OUTDOOR"}</Button>
              <React.Fragment>

                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}

                >
                  {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  SELECT YOUR ENVIRONMENT
                  </DialogTitle> */}
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers style={{ backgroundColor: "rgba(0,0,0,0.8)" }}>
                    <br />
                    <br />
                    <div className='container'>
                      <div>
                        <h6 className='text-white'>SELECT YOUR ENVIRONMENT</h6>
                        <br />
                        <Button variant="contained" size="large" style={{ width: "100%" }}
                          onClick={(e) => {
                            setSelectEnv(0)
                            setSelectEnvData(indoordata)
                            setWHData({ ...WHData, "width": 5, "height": 3, 'imageIndoor': "", 'groundHeight': 1 })
                            setColRom({ ...colRom, "col": 2, "row": 2 })
                            setSelectModelText(0);
                            updateIdScreen(-1)
                            setDataIndex(0)
                            setOpen(false);
                            setWidthCheck(true)
                            setHeightCheck(true)
                            setHeightCheckMain(true)
                          }}>
                          INDOOR
                        </Button>
                        <br />
                        <br />
                        <Button variant="contained" size="large" style={{ width: "100%" }}
                          onClick={(e) => {
                            setSelectEnv(1)
                            setSelectEnvData(outdoordata)
                            setWHData({ ...WHData, "width": 5, "height": 3, 'imageIndoor': "", 'groundHeight': 1, 'typeOutDoor': 0 })

                            setColRom({ ...colRom, "col": 2, "row": 2 })
                            setWidthCheck(true)
                            setHeightCheck(true)
                            setHeightCheckMain(true)
                            setSelectModelText(0);
                            updateIdScreen(-1);
                            setDataIndex(0);
                            setOpen(false);
                          }}
                        >
                          OUTDOOR
                        </Button>
                      </div>
                    </div>

                  </DialogContent>

                </BootstrapDialog>
              </React.Fragment>

            </div>


            {selectEnv == 0 ?
              <>
                <label for="ENVIRONMENT" style={{ marginTop: "9px" }}>ROOM TYPE</label>
                <Button variant="contained" onClick={handleClickOpenStandar} size="small" style={{ width: "100%" }}>STANDARD ROOM</Button>
              </>
              : <>
                <label for="ENVIRONMENT" style={{ marginTop: "9px" }}>OUTDOOR TYPE</label>
                <Button variant="contained" onClick={handleClickOpenOutDoor} size="small" style={{ width: "100%" }}>STANDARD TYPE</Button>
              </>}
            {/* OUTDOOR MODEL */}
            <React.Fragment>

              <BootstrapDialog
                onClose={handleCloseOutDoor}
                aria-labelledby="customized-dialog-title"
                open={openOutDoor}
              >
                {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  SELECT YOUR ENVIRONMENT
                  </DialogTitle> */}
                <IconButton
                  aria-label="close"
                  onClick={handleCloseOutDoor}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers style={{ backgroundColor: "rgba(0,0,0,0.8)" }}>
                  <br />
                  <br />
                  <div className='container'>
                    <div>
                      <h6 className='text-white'>SELECT TYPE OUTDOOR</h6>
                      <br />
                      <Button variant="contained" size="large" style={{ width: "100%" }}
                        onClick={(e) => {
                          setSelectEnv(1)
                          setSelectEnvData(outdoordata)
                          setWHData({ ...WHData, "width": 5, "height": 3, 'imageIndoor': "", 'groundHeight': 1, 'typeOutDoor': 0, "scale": 1 })

                          setColRom({ ...colRom, "col": 2, "row": 2 })

                          setSelectModelText(0);
                          updateIdScreen(-1);
                          setDataIndex(0);

                          setOpenOutDoor(false);
                        }}>
                        WALL OUTDOOR
                      </Button>
                      <br />
                      <br />
                      <Button variant="contained" size="large" style={{ width: "100%" }}
                        onClick={(e) => {
                          setSelectEnv(1)
                          setSelectEnvData(outdoordata)
                          setWHData({ ...WHData, "width": 5, "height": 3, 'imageIndoor': "", 'groundHeight': 0.023, 'typeOutDoor': 1, "scale": 1 })

                          setColRom({ ...colRom, "col": 2, "row": 2 })

                          setSelectModelText(0);
                          updateIdScreen(-1);
                          setDataIndex(0);
                          setOpenOutDoor(false);
                        }}
                      >
                        ROOF TOP
                      </Button>
                    </div>
                  </div>

                </DialogContent>

              </BootstrapDialog>
            </React.Fragment>
            {/* ENd  */}
            <Modal
              open={openModelStandar}
              onClose={handleCloseModelStandar}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleStandar} style={{ backgroundColor: "rgba(0,0,0,0.8)" }}>
                <Button
                  // aria-label="close"
                  onClick={handleCloseModelStandar}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}

                >
                  <CloseIcon />
                </Button>
                <div className="text-center">
                  <h3 className='text-white'>ROOM TYPE</h3>
                  <br />
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <div className='card' style={{ cursor: "pointer" }} onClick={() => {
                      setWHData({ ...WHData, 'imageIndoor': "image1.png", "imagePossition": "-80px" })
                      setOpenModelStandar(false);
                    }}>
                      <div className='text-center'>
                        <h6 className='mt-2 text-dark'>Stage Room</h6>
                      </div>
                      <img src={require('./../images/image1.png')} style={{ width: "100%", height: "150px" }} />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='card' style={{ cursor: "pointer" }} onClick={() => {
                      setWHData({ ...WHData, 'imageIndoor': "imageloby2.png", "imagePossition": "-130px" })
                      setOpenModelStandar(false);
                    }}>
                      <div className='text-center'>
                        <h6 className='mt-2 text-dark'>Lobby Room</h6>
                      </div>
                      <img src={require('./../images/imageloby2.png')} alt='finding' style={{ width: "100%", height: "150px" }} />
                    </div>
                  </div>



                </div>
              </Box>
            </Modal>

            <Box sx={{ flexGrow: 1 }} className='mt-2 ' >
              <Grid container spacing={2} columns={16}  >
                <Grid item xs={8}>
                  {/* <Item clas> */}
                  <div class="form-group">
                    <label for="ENVIRONMENT">WIDTH (M)</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <button class="btn btn-primary btn-dark-padding btn-sm" onClick={() => {

                          // let limitOfWidth = 8;
                          // if (selectEnv == 1) {
                          //   limitOfWidth = 10;
                          // }
                          // if (WHData.width > 0) {
                          //   if ((WHData.height - WHData.heightPointScale) != 0) {
                          //     let xvalue = (WHData.width * 100) / (selectEnvData[dataIndex].cabinetSizeWxhW / 10);
                          //     // alert(xvalue)
                          //     if (colRom.col < Math.floor(xvalue)) {

                          //       if (WHData.width == limitOfWidth) {
                          //         alert(WHData.scale)
                          //         setWHData({ ...WHData, "width": WHData.width - 1, "scale": WHData.scale + 0.1 })
                          //       } else {
                          //         setWHData({ ...WHData, "width": WHData.width - 1, "scale": WHData.scale + 0.1, "heightPointScale": WHData.heightPointScale - 0.5 })
                          //       }

                          //     } else {
                          //       setMessage("Screen out of boundary.")
                          //       setOpenAlert(true)
                          //     }
                          //   } else if (parseFloat(WHData.scale).toFixed(1) != 1) {
                          //     alert(parseFloat(WHData.scale).toFixed(1))
                          //     setWHData({ ...WHData, "width": WHData.width - 1, "scale": WHData.scale + 0.1 })
                          //   }

                          // }
                          if (WHData.width > 1) {
                            setWHData({ ...WHData, "width": WHData.width - 1, "boxWidth": WHData.boxWidth - ((dataIdScreenSelect?.cabinetSizeWxhW / 10) * 5) / 100, "boxHeight": WHData.boxHeight - ((dataIdScreenSelect?.cabinetSizeWxhH / 10) * 5) / 100, "heightPointScale": WHData.heightPointScale - 0.1 })
                          }
                        }}>-</button>
                      </div>
                      <span class="counter btn btn-primary btn-sm"> {WHData.width}</span>
                      <div class="input-group-append">
                        <button class="btn btn-primary btn-dark-padding btn-sm" onClick={() => {
                          setWidthCheck(true)
                          setHeightCheck(true)
                          // let limitOfHeight = 0.5;
                          // if (selectEnv == 1) {
                          //   limitOfHeight = 2;
                          // }
                          // if (widthCheck && heightCheck) {
                          //   setWHData({ ...WHData, "boxWidth": WHData.width + 1, "scale": WHData.scale - 0.01, "heightPointScale": WHData.heightPointScale + 0.03 })
                          // } else if (parseFloat(WHData.scale).toFixed(1) != 0.1) {
                          //   alert(parseFloat(WHData.scale).toFixed(1))
                          //   setWHData({ ...WHData, "width": WHData.width + 1, "scale": WHData.scale - 0.1 })
                          // }
                          // dataIdScreenSelect
                          setWHData({ ...WHData, "width": WHData.width + 1, "boxWidth": WHData.boxWidth + ((dataIdScreenSelect?.cabinetSizeWxhW / 10) * 5) / 100, "boxHeight": WHData.boxHeight + ((dataIdScreenSelect?.cabinetSizeWxhH / 10) * 5) / 100, "heightPointScale": WHData.heightPointScale + 0.1 })
                        }}>+</button>
                      </div>
                    </div>
                    {/* <ButtonGroup variant="contained" style={{ width: "100%" }} aria-label=" button group">
                      <Button size="small" style={{ width: "100%" }}>-</Button>
                      <Button size="small">



                      </Button>
                      <Button size="small" style={{ width: "100%" }}>+</Button>
                    </ButtonGroup> */}
                  </div>

                </Grid>
                <br />
                <Grid item xs={8}>

                  <div class="form-group">
                    <label for="ENVIRONMENT">HEIGHT (M)</label>

                    <div class="input-group" >
                      <div class="input-group-prepend">
                        <button class="btn btn-primary btn-dark-padding btn-sm" onClick={() => {
                          if (WHData.height > 1) {

                            // let xvalue = (WHData.height * 100) / (selectEnvData[dataIndex].cabinetSizeWxhH / 10);
                            // let valueCol = (WHData.groundHeight * 100) + colRom.row
                            // alert(heightCheck)
                            // if (heightCheck) {

                            //   setHeightCheckMain(true)
                            //   setWHData({ ...WHData, "height": WHData.height - 1 })

                            // } else {
                            //   setMessage("Screen out of boundary.")
                            //   setOpenAlert(true)
                            // }
                            setWHData({ ...WHData, "height": WHData.height - 1 })


                          }


                        }}>-</button>
                      </div>
                      <span class="counter btn btn-primary btn-sm " >{WHData.height}</span>
                      <div class="input-group-append">
                        <button class="btn btn-primary btn-dark-padding btn-sm" onClick={() => {
                           setWHData({ ...WHData, "height": WHData.height + 1 })
                          // if (heightCheckMain) {
                          //   setHeightCheck(true)
                          //   setWHData({ ...WHData, "height": WHData.height + 1 })
                          // } else {
                          //   setMessage("Screen out of boundary.")
                          //   setOpenAlert(true)
                          // }

                        }} style={{ width: "100%" }}>+</button>
                      </div>
                    </div>

                  </div>

                </Grid>
              </Grid>
            </Box>
            <br />

          </div>



        </div>

        <div className="row mt-3">
          <div className="col-md-12 bg-dark">
            <h6 className="text-center  text-white">DISPLAY CONFIGURATION</h6>
          </div>
          <div className="col-md-12  bg-dark card">
            <Box sx={{ flexGrow: 1 }} className='mt-2'>
              <Grid container spacing={2} columns={16} >
                <Grid item xs={8} >

                  <div class="form-group">
                    <label for="ENVIRONMENT" className='text-white'>DISPLAY MODEL</label>

                  </div>

                </Grid>
                <br />
                <Grid item xs={8}>
                  {idScreen != -1 ? <>
                    <div onClick={handleClickOpenModelView} className='text-center' style={{ cursor: "pointer", color: "white", borderBottom: "1px solid white" }} title='Click to show more'>

                      View Details

                    </div>
                  </> : <>
                    <div onClick={() => {
                      setMessage("Select Model!")
                      setOpenAlert(true)
                    }} className='text-center' style={{ cursor: "pointer", color: "white", borderBottom: "1px solid white" }}>

                      View Details

                    </div>
                  </>}

                  <Modal
                    open={openModelView}
                    onClose={handleCloseModelView}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                  >
                    <Box sx={styleStandar} className="bg-dark">
                      <Button
                        // aria-label="close"
                        onClick={handleCloseModelView}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                        style={{ color: "white" }}
                      >
                        <CloseIcon />
                      </Button>

                      <table class="table table-dark  table-responsive ">
                        {/* <thead>
                          <tr>

                            <th>Properties</th>
                            <th>Values</th>


                          </tr>
                        </thead> */}
                        <tbody>
                          <tr>
                            <th>CATEGORY</th>
                            <td>{selectEnvData[dataIndex].category}</td>
                          </tr>
                          <tr>
                            <th>MODEL</th>
                            <td>{selectEnvData[dataIndex].model}</td>
                          </tr>
                          <tr>
                            <th>DESCRIPTION</th>
                            <td>{selectEnvData[dataIndex].description}</td>
                          </tr>
                          <tr>
                            <th>PIXEL PITCH</th>
                            <td>{selectEnvData[dataIndex].pixelPitch}</td>
                          </tr>
                          <tr>
                            <th>LED TYPE</th>
                            <td>{selectEnvData[dataIndex].ledType}</td>
                          </tr>
                          <tr>
                            <th>BRIGHTNESS</th>
                            <td>{selectEnvData[dataIndex].brightness}</td>
                          </tr>
                          <tr>
                            <th>Cabinet Size WxH(mm)</th>
                            <td >{selectEnvData[dataIndex].cabinetSizeWxh}</td>
                          </tr>
                          <tr>
                            <th>Cabinet Resolution(px)</th>
                            <td>{selectEnvData[dataIndex].cabinetResolutionPX}</td>
                          </tr>
                          <tr>
                            <th>Module Size(mm)</th>
                            <td>{selectEnvData[dataIndex].moduleSize}</td>
                          </tr>
                          <tr>
                            <th>Module Resolution(px)</th>
                            <td>{selectEnvData[dataIndex].moduleResolutoin}</td>
                          </tr>
                          <tr>
                            <th>Module Config</th>
                            <td>{selectEnvData[dataIndex].moduleConfig}</td>
                          </tr>
                          <tr>
                            <th>Weight/Cabinet(KG)</th>
                            <td>{selectEnvData[dataIndex].weightCabinetKG}</td>
                          </tr>
                          <tr>
                            <th>Max Power Consumption(W/sqm)</th>
                            <td>{selectEnvData[dataIndex].maxPowerConsumption}</td>
                          </tr>
                          <tr>
                            <th>Avg Power Consumption (W/sqm)</th>
                            <td>{selectEnvData[dataIndex].avgPowerConsumption}</td>
                          </tr>

                        </tbody>
                      </table>
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
            </Box>
            <br />



            <Button variant="contained" onClick={handleClickOpenModel} onKeyDown={(e) => {
              if (e.key === 'F2') {
                handleClickOpenAdd()
                setWHData({ ...WHData, "addNewData": 1 })

              }
            }} size="small" style={{ width: "100%" }}>

              {
                selectModelText === 0 ? "Select Model"
                  : selectModelText === 2 ? "Custom Data"
                    : selectEnvData[dataIndex].model
              }
              {/* {selectModelText == 0 ? "Select Model" : selectEnvData[dataIndex].model} */}
            </Button>
            <Modal
              open={openModelAdd}
              onClose={handleCloseModelAdd}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleStandar} className="bg-dark">
                <Button
                  // aria-label="close"
                  onClick={handleCloseModelAdd}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </Button>

                <Grid container spacing={2}>

                  <div className="text-center">
                    <h3 className='text-white'>Custom Input Values</h3>
                    <br />
                  </div>
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Type ENVIRONMENT"
                      // value={activity}
                      // onChange={handleChangeAdd}
                      InputLabelProps={{ style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth

                    >
                      <MenuItem value="Indoor">Indoor</MenuItem>
                      <MenuItem value="Outdoor">Outdoor</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Cabinet Size W"
                      variant="outlined"
                      type='number'
                      value={datacustom.cabinetSizeWxhW}
                      InputLabelProps={{ style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth
                      onChange={(e) => {
                        setDatacustom({ ...datacustom, "cabinetSizeWxhW": e.target.value })
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Cabinet Size H"
                      variant="outlined"
                      type='number'
                      value={datacustom.cabinetSizeWxhH}
                      InputLabelProps={{ style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white' } }}
                      onChange={(e) => {
                        setDatacustom({ ...datacustom, "cabinetSizeWxhH": e.target.value })
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="PIXEL PITCH"
                      type='number'
                      variant="outlined"
                      value={datacustom.pixelPitch}
                      InputLabelProps={{ style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth
                      onChange={(e) => {
                        setDatacustom({ ...datacustom, "pixelPitch": e.target.value, "cabinetResolutionPXW": datacustom.cabinetSizeWxhW / e.target.value, "cabinetResolutionPXH": datacustom.cabinetSizeWxhH / e.target.value })
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Cabinet Resolution W"
                      variant="outlined"
                      type='number'
                      value={datacustom.cabinetResolutionPXW}
                      InputLabelProps={{ style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth
                      onChange={(e) => {
                        setDatacustom({ ...datacustom, "cabinetResolutionPXW": e.target.value })
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Cabinet Resolution H"
                      type='number'
                      variant="outlined"
                      value={datacustom.cabinetResolutionPXH}
                      InputLabelProps={{ style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white' } }}
                      fullWidth
                      onChange={(e) => {
                        setDatacustom({ ...datacustom, "cabinetResolutionPXH": e.target.value })
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className='text-center'>
                    <Button variant="contained" color="primary" onClick={() => {

                      setOpenModelAdd(false)
                      updateIdScreen(-2)
                      setDataIdScreenSelect(datacustom)
                      setSelectModelText(2)
                      setColRom({ ...colRom, "col": 2, "row": 2 })
                    }} >
                      Submit
                    </Button>
                  </Grid>
                </Grid>


              </Box>
            </Modal>

            <Modal
              open={openModel}
              onClose={handleCloseModel}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="bg-dark">
                <Button
                  // aria-label="close"
                  onClick={handleCloseModel}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </Button>

                <table class="table table-dark table-hover table-responsive ">
                  <thead>
                    <tr>
                      <th>CATEGORY</th>
                      <th>MODEL</th>
                      <th>DESCRIPTION</th>
                      <th>PIXEL PITCH</th>
                      <th>LED TYPE</th>
                      <th>BRIGHTNESS</th>
                      {/* <th>Cabinet Size WxH(mm)</th>
                      <th>Cabinet Resolution(px)</th>
                      <th>Module Size(mm)</th>
                      <th>Module Resolution(px)</th>
                      <th>Module Config</th>
                      <th>Weight/Cabinet(KG)</th>
                      <th>Max Power Consumption(W/sqm)</th> */}

                    </tr>
                  </thead>
                  <tbody>

                    {
                      selectEnvData.map((selectEnvData, x) => {
                        return (
                          <tr style={{ cursor: "pointer" }} onClick={() => {
                            setDataIndex(x)
                            setOpenModel(false)
                            updateIdScreen(x)
                            setDataIdScreenSelect(selectEnvData)
                            setSelectModelText(1)
                            setColRom({ ...colRom, "col": 2, "row": 2 })
                            setWidthCheck(true)
                            setHeightCheck(true)
                            setWHData({ ...WHData, "addNewData": 0 })

                          }}>
                            {/* <th scope="row">1</th> */}
                            <td>{selectEnvData.category}</td>
                            <td>{selectEnvData.model}</td>
                            <td>{selectEnvData.description}</td>
                            <td>{selectEnvData.pixelPitch}</td>
                            <td>{selectEnvData.ledType}</td>
                            <td>{selectEnvData.brightness}</td>
                            {/* <td>{selectEnvData.cabinetSizeWxh}</td>
                        <td>{selectEnvData.cabinetResolutionPX}</td>
                        <td>{selectEnvData.moduleSize}</td>
                        <td>{selectEnvData.moduleResolutoin}</td>
                        <td>{selectEnvData.moduleConfig}</td>
                        <td>{selectEnvData.weightCabinetKG}</td>
                        <td>{selectEnvData.maxPowerConsumption}</td> */}

                          </tr>
                        )
                      })



                    }



                  </tbody>
                </table>
              </Box>
            </Modal>
            <Box sx={{ flexGrow: 1 }} className='mt-2'>
              <Grid container spacing={2} columns={16} >
                <Grid item xs={8}>

                  <div class="form-group">
                    <label for="ENVIRONMENT" className='text-white'>COLUMNS</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <button class="btn btn-primary btn-dark-padding btn-sm" onClick={(e) => {

                          if (selectModelText != 0) {
                            if (colRom.col <= 1) {
                              // alert("Row is going -")
                              // setColRom({ ...colRom, "col": 0 })
                            } else {
                              setWidthCheck(true)
                              setColRom({ ...colRom, "col": colRom.col - 1 })
                              setDataShare(colRom)
                            }
                          }


                        }}>-</button>
                      </div>
                      <span class="counter btn btn-primary btn-sm">{colRom.col}</span>
                      <div class="input-group-append">
                        <button class="btn btn-primary btn-dark-padding btn-sm" onClick={(e) => {
                          if (selectModelText != 0) {
                            // let xvalue = (WHData.width * 100) / (selectEnvData[dataIndex].cabinetSizeWxhW / 10);
                            //  alert(widthCheck)
                            if (widthCheck) {

                              setColRom({ ...colRom, "col": colRom.col + 1 })
                              setDataShare(colRom)
                            } else {
                              setMessage("Screen out of boundary.")
                              setOpenAlert(true)
                            }
                          }


                        }}>+</button>
                      </div>
                    </div>
                    {/* <ButtonGroup variant="contained" style={{ width: "100%" }} aria-label=" button group">
                      <Button size="small" style={{ width: "100%" }}>-</Button>
                      <Button size="small"></Button>
                      <Button size="small" style={{ width: "100%" }}>+</Button>
                    </ButtonGroup> */}
                  </div>

                </Grid>
                <br />
                <Grid item xs={8}>

                  <div class="form-group">
                    <label for="ENVIRONMENT" className='text-white'>ROWS</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <button class="btn btn-primary btn-dark-padding btn-sm" onClick={(e) => {
                          if (selectModelText != 0) {
                            if (colRom.row <= 1) {
                              // alert("Row is going -")
                              // setColRom({ ...colRom, "row": 0 })
                            } else {
                              setHeightCheck(true)
                              setColRom({ ...colRom, "row": colRom.row - 1 })
                              setDataShare(colRom)
                            }
                          }


                        }}>-</button>
                      </div>
                      <span class="counter btn btn-primary btn-sm">{colRom.row}</span>
                      <div class="input-group-append">
                        <button class="btn btn-primary btn-dark-padding btn-sm"
                          onClick={(e) => {
                            if (selectModelText != 0) {
                              // let xvalue = (WHData.height * 100) / (selectEnvData[dataIndex].cabinetSizeWxhH / 10);
                              // let valueCol = (WHData.groundHeight * 100) + colRom.row
                              // alert(xvalue)
                              if (heightCheck) {

                                setColRom({ ...colRom, "row": colRom.row + 1 })
                                setDataShare(colRom)
                              } else {
                                setMessage("Screen out of boundary.")
                                setOpenAlert(true)
                              }
                            }



                          }}
                        >+</button>
                      </div>
                    </div>
                    {/* <ButtonGroup variant="contained" style={{ width: "100%" }} aria-label=" button group">
                      <Button size="small" style={{ width: "100%" }}>-</Button>
                      <Button size="small"></Button>
                      <Button size="small" style={{ width: "100%" }}>+</Button>
                    </ButtonGroup> */}
                  </div>

                </Grid>
              </Grid>
            </Box>
            <br />
            <div class="form-group">
              <input type='file' accept="image/*" style={{ display: 'none', width: "100%" }}
                ref={fileInputRef}
                onChange={(e) => {
                  imageUpload(e)
                }} size="small" />
              <Button onClick={handleButtonClick} variant='contained' style={{ width: "100%" }}>

                Choose Image
              </Button>
            </div>

            {selectEnv == 0 || WHData.typeOutDoor == 0 ? <>
              <div class="form-group" style={{ marginTop: "9px" }}>
                {/* <label for="ENVIRONMENT" className='text-white'></label> */}
                <div class="form-group">
                  <label for="ENVIRONMENT" className='text-white'>HEIGHT FROM GROUND (M)</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button class="btn btn-primary btn-dark-padding btn-sm" onClick={decreaseHight} >-</button>
                    </div>
                    <span class="counter btn btn-primary btn-sm" style={{ width: "60px" }}>{WHData.groundHeight}</span>
                    <div class="input-group-append">
                      <button class="btn btn-primary btn-dark-padding btn-sm"
                        onClick={increasehighte}
                      >+</button>
                    </div>
                  </div>
                  {/* <ButtonGroup variant="contained" style={{ width: "100%" }} aria-label=" button group">
                      <Button size="small" style={{ width: "100%" }}>-</Button>
                      <Button size="small"></Button>
                      <Button size="small" style={{ width: "100%" }}>+</Button>
                    </ButtonGroup> */}
                </div>
                {/* <ButtonGroup variant="contained" style={{ width: "100%" }} aria-label=" button group">
                <Button size="small"  style={{ width: "100%", borderColor: "white" }}>-</Button>
                <Button size="small" style={{ borderColor: "white" }}></Button>
                <Button size="small" style={{ width: "100%" }}>+</Button>
              </ButtonGroup> */}
              </div>

            </> : <></>}
            <br />
          </div>



        </div>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert
            onClose={handleCloseAlert}
            severity="info"
            variant="filled"
            sx={{ width: '100%', background: "black" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div >
    </>
  );
}

export default Sidebar;


const Item = styled(Paper)(({ theme }) => ({
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    // padding: theme.spacing(2),
    // width:"400px"
  },
  '& .MuiDialogActions-root': {
    // padding: theme.spacing(1),
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  height: "650px",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  // margin:"10px",
  overflowY: 'scroll',
};

const styleStandar = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height: "660px",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  // margin:"10px",
  // overflowY: 'scroll',
};